html,body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100%;
  height: 100%;
}
#contentWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#divContent {
   /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;*/
    margin-left: 20px;
    margin-right: 20px;
}

#divAppBody {
    margin-bottom: 20px;
    min-height: calc(100vh - 90px);
}

.completedTab {
    color: #1c69d4 !important;
    border-bottom: 2px solid #1c69d4 !important;
}
.activeTab {
    color: #ffad1f !important;
}
.disabledTab {
    border-bottom: 2px solid #8e8e8e !important;
}
.navigationTab {
    font-weight: bold !important;
}
.paper {
    margin: 10px;
    padding: 20px;
}

.rc-pagination-item-active {
    background-color: #1c69d4 !important;
}

a.rc-pagination-item-active:active {
    text-decoration: none;
}

.rc-pagination li {
    border-radius: 0 !important;
}

.rc-pagination li:hover {
    border-color: #1c69d4 !important;
}

.rc-pagination li:hover a {
    color: #1c69d4 !important;
}

li.rc-pagination-item-active:hover a {
    color: white !important;
}

#tblConfirmation tbody th, td {
    font-size: 14px !important;
}
#tblConfirmation tbody td {
    font-weight: bold;
}

